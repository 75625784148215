export default {
  props:{
    draggable: {
      required: true,
      type: Boolean,
    },
    forSingleItem: {
      required: true,
      type: Boolean,
    },
  },
  data:() => ({
    parent: null,
    mousePressed: false,
  }),
  created(){
    const collection = [...document.getElementsByClassName("drop-zone")];
    const [parent] = collection;
    this.parent = parent;
  },
  methods:{
    mouseUp() {
      this.$emit("update:draggable", false);
    },
    mouseDown(evt) {
      if (
        [...evt.target.classList].includes("allow-drag") &&
          !this.forSingleItem
      ) {
        this.$emit("update:draggable", true);
      }
    },
    startDrag(evt, document) {
      if(this.hasChanges && this.fields_been_visited){
        return this.$snackbar({text:"Сохраните изменения перед сортировкой.",color:"orange", top:false, right: false});
      }
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("document", document.id);
  
    },
  }
}