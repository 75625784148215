<template>
  <div>
    <v-row>
      <v-col cols="12">
        <toolbox />
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col
            cols="6"
            :class="{'blocked-box' : isRegistered}"
          >
            <payment-sum />
          </v-col>
          <v-col cols="6">
            <customs-info />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <main-info />
      </v-col>
      <v-col cols="6">
        <customs-points />
      </v-col>
      <v-col cols="6">
        <payer />
      </v-col>
      <v-col cols="6">
        <declarant />
      </v-col>
      <v-col cols="12">
        <filler />
      </v-col>
      <v-col cols="12">
        <documents />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Toolbox from './toolbox.vue'
import CustomsInfo from './customs-info.vue'
import MainInfo from './main-info.vue'
import CustomsPoints from './customs-points.vue'
import Payer from './payer.vue'
import Declarant from './declarant.vue'
import Filler from './filler.vue'
import Documents from './documents/documents.vue'

import PaymentSum from './payment-sum.vue'
import { mapGetters } from 'vuex'
export default {
  components:{
    Toolbox,
    CustomsInfo,
    MainInfo,
    CustomsPoints,
    Payer,
    Declarant,
    Filler,
    Documents,
    PaymentSum,
  },
  computed:{
    ...mapGetters({
      selected:'ensuring/getSelected'
    }),
    isRegistered(){
      return this.selected?.registration_details?.reg_num_a
    }
  }
}
</script>
