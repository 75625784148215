<template>
  <div
    :id="block_id"
    v-click-outside="triggerOnFocus"
    class="document__row"
    :draggable="draggable"
    @dragstart="startDrag($event, document)"
    @mouseup="mouseUp"
  >
    <div class="document__sort">
      <div
        class="allow-drag"
        @mousedown="mouseDown($event)"
      >
        {{ document.sort_index }}
      </div>
    </div>

    <div class="document__code">
      <v-tooltip
        bottom
        max-width="300px"
        :disabled="!document.doc_code"
      >
        <template #activator="{ on }">
          <v-autocomplete
            ref="doc_code"
            v-model="document.doc_code"
            auto-select-first
            :items="doc_types"
            item-text="code"
            :filter="filterBySearchField"
            item-value="code"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            v-on="on"
            @change="updateDocName"
          />
        </template>
        <span>{{ docName }}</span>
      </v-tooltip>

      <v-btn
        tabindex="-1"
        min-width="36px"
        max-width="36px"
        color="#5CB7B1"
        class="ml-1 elevation-0"
        @click="showDocuments"
      >
        <v-icon>
          mdi-format-list-bulleted
        </v-icon>
      </v-btn>
    </div>

    <div
      class="document__name"
    >
      <v-text-field
        ref="doc_name"
        v-model="document.doc_name"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </div>

    <div
      class="document__number"
    >
      <v-text-field
        ref="doc_number"
        v-model="document.doc_number"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </div>

    <div class="document__date">
      <custom-date-picker
        ref="doc_date"
        v-model="document.doc_date"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
      />
    </div>

    <div class="d-flex mx-1">
      <v-btn
        tabindex="-1"
        max-width="36px"
        min-width="36px"
        color="transparent"
        class="elevation-0 document-item-delete"
        @click="deleteDocument"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>

      <v-btn
        v-if="index === presentedDocuments.length - 1"
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="addNewDoc"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { deleteNulls } from "@/helpers/inputs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { convertEmptyStringsToNull } from "@/helpers/objects";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import { showCatalog } from "@/events/statistics";
import { documentTypeSelected } from "@/events/statistics";
import CustomDatePicker from "@/components/shared/custom-date-picker"
import singleDocumentDrag from "@/mixins/draggable/single-document-drag.mixin";
import customFieldChecker from '@/mixins/custom-field-checker'
export default {
  components:{
    CustomDatePicker
  },
  mixins: [blockAutoUpdate, filterBySearchFieldMixin, singleDocumentDrag, customFieldChecker],
  props: {
    index: {
      required: true,
      type: Number,
    },
    item: {
      required: true,
      type: Object,
    },
    presentedDocuments:{
      required: true,
      type: Array
    },
  },
  data: () => ({
    selected: null,
    isIntersecting: false,
    block_id: null,
    document: {
      id: "",
      declaration_id: "",
      doc_code: "",
      doc_name:"",
      doc_number: "",
      doc_date: "",
      sort_index: "",
    },
    noRegistration: true,
  }),

  computed: {
    ...mapGetters({
      doc_types: "catalogs/getNsiTypesOfDocument",
      customsPoints: "catalogs/getNsiCustomsPoints",
    }),
    docName() {
      const { name } =
        this.doc_types.find((i) => i.code === this.document.doc_code) || {};
      return name || "";
    },
  },
  watch: {
    item: {
      handler(newVal) {
        this.block_id = `ensuring-present-document-${this.item.id}`;
        for (let key in newVal) {
          if (key in this.document) {
            this.document[key] = newVal[key];
          }
        }
      },
      deep: true,
      immediate:true
    },
  },
  created() {
    documentTypeSelected.subscribe(this.updatesResolver);
  },
  beforeDestroy() {
    documentTypeSelected.unsubscribe();
  },
  methods: {
    addNewDoc(){
      this.$emit('addNewDoc')
    },
    updateSelected(){
      this.$emit('select', this.item.id)
    },
    deleteNulls,
    deleteDocument() {
      const { id } = this.item;
      const index = this.index;
      this.$emit("delete", {
        id,
        index,
      });
    },
    uploadData() {
      const index = this.index;
      const payload = convertEmptyStringsToNull(this.document);
      return this.$store.dispatch("ensuring/updateDocument", {
        index,
        payload,
      });
    },
    showDocuments() {
      showCatalog.trigger({
        type: "doctypes",
        id: this.document.id,
        search: this.document.doc_code,
      });
    },
    updatesResolver(payload){
      this.checkAndUpdateDocType(payload)
      this.checkAndUpdateDocName(payload)
    },
    checkAndUpdateDocName({id, code}){
      if (this.document.id === id) {
        this.updateDocName(code)
      }
    },
    updateDocName(code){
      const {shortName} = this.doc_types.find(i => i.code === code) ?? {}
      if(shortName){
        this.document.doc_name = shortName;
        this.fieldsBeenVisitedByUser();
      }
    },
    checkAndUpdateDocType({id, code}) {
      if (this.document.id === id) {
        this.document.doc_code = code;
        this.fieldsBeenVisitedByUser();
      }
    },
  },
};
</script>
<style scoped>
.document__row{
    display: flex;
    align-items: center;
}
.document__select{
    display: flex;
    justify-content: center;
    align-items: center;
}
.document__sort{
  width: 60px !important;
  padding: 0 10px 0 0;
}
.document__name{
  width: 300px !important;
  display: flex;
  align-items: center;
  padding: 0 10px 0 0;
}
.document__code{
  width: 180px !important;
  display: flex;
  align-items: center;
  padding: 0 10px 0 0;
}
.document__number{
  width: 300px !important;
  padding: 0 10px 0 0;
}
.document__date{
  width: 160px !important;
}
.document__addition{
  width: 300px !important;
}
.h-full {
  height: 100%;
}
.document-row:last-child {
  padding: 0 10px 4px 0 !important;
}
</style>
