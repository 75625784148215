<template>
  <v-container
    fluid
    class="px-0"
  >
    <div
      class="statistics-box position-relative ensuring-documents-list"
      :class="{'blocked-box' : isRegistered}"
    >
      <v-col cols="12">
        <v-row>
          <v-col
            cols="2"
            class="pb-0"
          >
            <span class="font-weight-bold">
              Документы ({{ presented_documents.length }})
            </span>
          </v-col>
          <v-col
            cols="12"
          >
            <v-btn
              v-if="!presented_documents.length"

              class="document-add elevation-0 px-15"
              color="transparent"
              @click="addNewDocument"
            >
              Добавить <v-icon>mdi-plus</v-icon>
            </v-btn>
            <div class="d-flex px-1">
              <div class="header__sort">
                <small>№ п/п</small>
              </div>
              <div class="header__code">
                <small>Код</small>
              </div>
              <div
                class="header__name"
              >
                <small>Наименование документа</small>
              </div>
              <div
                class="header__number"
              >
                <small>Номер документа</small>
              </div>
              <div class="header__date">
                <small>Дата</small>
              </div>
            </div>
            <v-virtual-scroll
              bench="10"
              height="100%"
              min-height="100px"
              item-height="50px"
              :items="presented_documents"
              class="drop-zone"
              @drop="onDrop($event, 1)"
              @dragover.prevent
              @dragenter.prevent
            >
              <template #default="{index,item}">
                <single-document
                  :ref="'doc_' + index"
                  :key="'doc_' + index"
                  :item="item"
                  :index="index"
                  :for-single-item="false"
                  :draggable.sync="draggable"
                  :presented-documents="presented_documents"
                  @delete="deleteDocument"
                  @addNewDoc="addNewDocument"
                />
              </template>
            </v-virtual-scroll>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import SingleDocument from "./single-document.vue";
import { convertEmptyStringsToNull } from "@/helpers/objects";
import documentsDropZone from '@/mixins/draggable/documents-drop-zone.mixin'
import {presented_documents as onPresentedDocuments} from "@/events/statistics/control";
import highlightFieldsParent from "@/mixins/control/highlight-fields-parent";
export default {
  components: {
    SingleDocument,
  },
  mixins: [highlightFieldsParent, documentsDropZone],
  data: () => ({
    pinName:'documents',
    selectedDocs: [],
    presented_documents: [],
    controlSettings: {
      controlBlockSelector: ".ensuring-documents-list",
      controlElementSelector: ".ensuring-documents-list .v-virtual-scroll",
    },
  }),
  computed: {
    ...mapGetters({
      selected: "ensuring/getSelected",
      pinned:'ui/getPinned'
    }),
    isRegistered(){
      return this.selected?.registration_details?.reg_num_a
    },
    tableHeight(){
      return this.presented_documents.length * 50
    }
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate:true
    },
  },
  created() {
    onPresentedDocuments.subscribe(this.onControl);
  },
  beforeDestroy() {
    onPresentedDocuments.unsubscribe();
  },
  methods: {
    setFields() {
      this.presented_documents = this.selected?.presented_documents || [];
    },
    addNewDocument() {
      if (this.orderChanged) {
        this.checkOrderAndUpdate().then(() => {
          this.createDocument();
        });
      } else {
        this.createDocument();
      }
    },
    createDocument() {
      this.$store.dispatch("ensuring/addDocument")
    },
    deleteDocument(payload) {
      if (this.orderChanged) {
        this.checkOrderAndUpdate().then(() => {
          this.$store.dispatch("ensuring/deleteDocument", payload).catch(() => {
            this.$snackbar({text:"Ошибка", color:"red", top:false, right: false})
          })
        });
      } else {
        this.$store.dispatch("ensuring/deleteDocument", payload).catch(() => {
          this.$snackbar({text:"Ошибка", color:"red", top:false, right: false})
        })
      }
    },

    checkOrderAndUpdate() {
      if (this.orderChanged) {
        const docs = this.presented_documents.map((document, idx) => {
          return {
            ...document,
            sort_index: idx + 1,
          };
        });
        const payload = docs.map((doc) => convertEmptyStringsToNull(doc));
        return this.$store
          .dispatch("ensuring/updateDocument", { payload })
          .then(() => {
            this.orderChanged = false;
          }).catch(()=> {
            this.$snackbar({text:"Ошибка при сортировке",color:"red", top:false, right: false});
          });
      }
    },
  },
};
</script>
<style scoped>
.header__sort{
  width: 60px !important;
}
.header__code{
  width: 180px !important;
  padding: 0 10px 0 0;
}
.header__name{
  width: 300px !important;
  padding: 0 10px 0 0;
}
.header__number{
  width: 300px !important;
  padding: 0 10px 0 0;
}
.header__date{
  width: 160px !important;
}
.v-data-table__wrapper {
  padding-bottom: 0 !important;
}
.document-add {
  position: absolute;
  top:50%;
  right: 50%;
  transform: translateX(50%);
  z-index: 1;
}
</style>
