<template>
  <div
    class="pb-16 mx-auto mt-10 px-5"
    style="max-width: 1440px"
  >
    <ensuring-form />
    <catalogs />
    <shared-buffer />
    <distinct-division-notification />
  </div>
</template>
<script>

import EnsuringForm from '@/components/ensuring/form/index.vue'
import Catalogs from "@/components/catalogs/modals/resolver.vue";
import SharedBuffer from "@/components/shared/shared-buffer.vue";
import DistinctDivisionNotification from "@/components/documents/warnings/distinct-division-notification.vue";
import {mapGetters} from "vuex";
import {setDeclarationTitle} from "@/helpers/text";
export default {
  components:{
    DistinctDivisionNotification,
    SharedBuffer,
    EnsuringForm,Catalogs
  },
  provide:{
    rootNamespace:'ensuring'
  },
  computed: {
    ...mapGetters({
      selected: "ensuring/getSelected",
    }),
  },
  watch:{
    selected:{
      handler(ensuring){
        const {remark, out_number: outNumber} = ensuring?.about_declaration ?? {}
        setDeclarationTitle({
          outNumber,
          remark,
          prefix:"СО"
        })
      },
      deep:true
    }
  },
  created(){
    this.$store.dispatch("catalogs/fetchAll")
    const id = this.$route.params.id
    this.$store.dispatch("ensuring/getDocumentById", id).catch(err => {
      if(err.response.status === 403){
        this.$router.push('/403')
      }
    });
  },
}
</script>
