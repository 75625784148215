<template>
  <v-card
    color="grey"
    class="lighten-3 elevation-0 rounded-lg pa-3"
    :class="{'blocked-box' : isRegistered}"
  >
    <h4>Декларант</h4>
    <v-row
      :id="block_id"
      v-click-outside="triggerOnFocus"
    >
      <v-col
        cols="3"
        class="pr-0 pb-0"
      >
        <label>
          ИНН/УНН
          <v-text-field
            v-model="declarant.declarant_inn"
            outlined
            background-color="white"
            dense
            hide-details="auto"
          />
        </label>
      </v-col>
      <v-col
        cols="7"
        class="pr-0 pb-0"
      >
        <label>
          Наименование организации
          <v-text-field
            v-model="declarant.declarant_name"
            outlined
            background-color="white"
            dense
            hide-details="auto"
          />
        </label>
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <label>
          Страна
          <v-autocomplete
            v-model="declarant_json.country_letter"
            auto-select-first
            outlined
            dense
            hide-details="auto"
            :filter="filterBySearchField"
            background-color="white"
            :items="countries"
            item-text="letterCode"
            item-value="letterCode"
          />
        </label>
      </v-col>
      <v-col
        cols="2"
        class="pr-0 pb-0"
      >
        <label>
          Индекс
          <v-text-field
            v-model="declarant_json.postal_index"
            outlined
            background-color="white"
            dense
            hide-details="auto"
          />
        </label>
      </v-col>
      <v-col
        cols="5"
        class="pr-0 pb-0"
      >
        <label>
          Область/Район
          <v-text-field
            v-model="declarant_json.region"
            outlined
            background-color="white"
            dense
            hide-details="auto"
          />
        </label>
      </v-col>
      <v-col
        cols="5"
        class=" pb-0"
      >
        <label>
          Город/Деревня
          <v-text-field
            v-model="declarant_json.city"
            outlined
            background-color="white"
            dense
            hide-details="auto"
          />
        </label>
      </v-col>
      <v-col
        cols="8"
        class="pr-0"
      >
        <label>
          Адрес
          <v-text-field
            v-model="declarant_json.street_house"
            outlined
            background-color="white"
            dense
            hide-details="auto"
          />
        </label>
      </v-col>
      <v-col cols="4">
        <label>
          Телефон
          <v-text-field
            v-model="declarant_json.phone"
            outlined
            background-color="white"
            dense
            hide-details="auto"
          />
        </label>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex'
import { filterBySearchFieldMixin } from "@/mixins/catalogs"
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { getCountryNameByCode } from "@/helpers/catalogs"
export default {
  mixins:[blockAutoUpdate, filterBySearchFieldMixin],
  data:() => ({
    block_id: 'statistics-declarant',
    declarant:{
      declaration_id: null,
      declarant_inn: null,
      declarant_name: null,
    },
    declarant_json: {
      country_name: null,
      country_letter: null,
      postal_index: null,
      region: null,
      city: null,
      street_house: null,
      phone: null
    }
  }),
  computed:{
    ...mapGetters({
      selected: 'ensuring/getSelected',
      currencies: "catalogs/getNsiCurrencies",
      countries: "catalogs/getNsiCountries"
    }),
    isRegistered(){
      return this.selected?.registration_details?.reg_num_a
    },
  },
  watch: {
    selected: {
      handler(){
        this.setFields();
      },
      deep:true
    }
  },
  methods:{
    uploadData(){
      const payload = {
        ...this.declarant,
        declarant_json:{
          ...this.declarant_json,
          country_name: getCountryNameByCode(this.countries, this.declarant_json.country_letter),
        }
      }
      const declarant = this.convertEmptyStringsToNull(payload);
      return this.$store.dispatch("ensuring/uploadBlockData", {
        name: "declarant",
        value: declarant,
      });

    },
    setFields() {
      const declarant = this.selected.declarant || {}
      const declarant_json = declarant.declarant_json

      Object.keys(declarant).forEach(key => {
        if (key in this.declarant) {
          this.declarant[key] = declarant[key];
        }
      })

      Object.keys(declarant_json).forEach(key => {
        if (key in this.declarant_json) {
          this.declarant_json[key] = declarant_json[key];
        }
      })
    },
  }
}
</script>
