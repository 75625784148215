<template>
  <v-card
    color="grey"
    class="lighten-3 elevation-0 rounded-lg pa-3"
    :class="{'blocked-box' : isRegistered}"
  >
    <v-row
      :id="block_id"
      v-click-outside="triggerOnFocus"
      justify="space-between"
    >
      <v-col
        cols="5"
        class="pr-0"
      >
        <label>
          Таможня отправления
          <v-autocomplete
            v-model="about_customs.dispatch_customs_code"
            auto-select-first
            :items="points"
            item-text="search"
            item-value="code"
            outlined
            background-color="white"
            dense
            hide-details="auto"
            :filter="filterBySearchField"
          />
        </label>
      </v-col>
      <v-col
        cols="5"
      >
        <label>
          Таможня назначения
          <v-autocomplete
            v-model="about_customs.destination_customs_code"
            auto-select-first
            :items="points"
            item-text="search"
            item-value="code"
            outlined
            background-color="white"
            dense
            hide-details="auto"
            :filter="filterBySearchField"
          />
        </label>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
export default {
  mixins:[filterBySearchFieldMixin, blockAutoUpdate],
  data:() => ({
    block_id:'statistics-customs-points',
    about_customs:{
      declaration_id:null,
      destination_customs_code: null,
      dispatch_customs_code: null
    }
  }),
  computed:{
    ...mapGetters({
      selected:"ensuring/getSelected",
      points:'catalogs/getNsiCustomsPoints'
    }),
    isRegistered(){
      return this.selected?.registration_details?.reg_num_a
    }
  },
  watch:{
    selected:{
      handler(){
        this.setFields()
      },
      deep:true
    }
  },
  methods:{
    getCustomsName(code){
      const customs = this.points.find(i => i.code === code)
      return customs?.name ?? null
    },
    setFields(){
      const {about_customs} = this.selected
      Object.keys(this.about_customs).forEach(key => {
        if(key in about_customs){
          this.about_customs[key] = about_customs[key]
        }
      })
    },
    uploadData(){
      const payload = {
        ...this.about_customs,
        destination_customs_name:this.getCustomsName(this.about_customs.destination_customs_code),
        dispatch_customs_name: this.getCustomsName(this.about_customs.dispatch_customs_code)
      }
      const about_customs = this.convertEmptyStringsToNull(payload);
      return this.$store.dispatch("ensuring/uploadBlockData", {
        name: "about_customs",
        value: about_customs,
      });
    }
  }
}
</script>
