<template>
  <v-card
    color="grey"
    class="lighten-3 elevation-0 rounded-lg pa-3"
    :class="{'blocked-box' : isRegistered}"
  >
    <v-row
      :id="block_id"
      v-click-outside="triggerOnFocus"
    >
      <v-col
        cols="2"
        class="pr-0"
      >
        <label>
          Код СО
          <v-menu offset-x>
            <template #activator="{ on }">
              <v-icon
                tabindex="-1"
                dense
                class="mx-1"
                small
                v-on="on"
              >mdi-tune</v-icon>
            </template>
            <v-list>
              <v-list-item
                @click="openGuaranteeTypesSo"
              >Поиск в справочнике</v-list-item>
            </v-list>

          </v-menu></label>
        <v-autocomplete
          ref="payment_type"
          v-model="about_guarantee.payment_type"
          auto-select-first
          :items="types"
          item-text="code"
          item-value="code"
          outlined
          background-color="white"
          dense
          hide-details="auto"
          :filter="filterBySearchField"
        />
      </v-col>
      <v-col
        cols="2"
        class="pr-0"
      >
        <label>
          Основание
          <v-menu offset-x>
            <template #activator="{ on }">
              <v-icon
                tabindex="-1"
                dense
                class="mx-1"
                small
                v-on="on"
              >mdi-tune</v-icon>
            </template>
            <v-list>
              <v-list-item
                @click="openGuaranteeReasons"
              >Поиск в справочнике</v-list-item>
            </v-list>
          </v-menu>

        </label>
        <v-autocomplete
          ref="guarantee_type"
          v-model="about_guarantee.guarantee_type"
          auto-select-first
          item-text="code"
          item-value="code"
          :items="reasons"
          outlined
          background-color="white"
          dense
          hide-details="auto"
          :filter="filterBySearchField"
        />
      </v-col>
      <v-col
        cols="4"
        class="pr-0"
      >
        <label>
          Рег.номер обеспеч.
          <v-menu offset-x>
            <template #activator="{ on }">
              <v-icon
                tabindex="-1"
                dense
                class="mx-1"
                small
                v-on="on"
              >mdi-tune</v-icon>
            </template>
            <v-list>
              <v-list-item
                @click="openGuarantee"
              >Поиск в справочнике</v-list-item>
            </v-list>
          </v-menu>

        </label>
        <v-autocomplete
          ref="guarantee_number"
          v-model="about_guarantee.guarantee_number"
          :items="guarantee"
          auto-select-first
          item-text="number"
          item-value="number"
          outlined
          background-color="white"
          dense
          hide-details="auto"
          @change="updateCurrency"
        />
      </v-col>
      <v-col

        class="pr-0"
      >
        <label>
          Сумма
          <v-text-field
            ref="sum"
            v-model="about_guarantee.sum"
            outlined
            background-color="white"
            dense
            hide-details="auto"
          />
        </label>
      </v-col>
      <v-col cols="2">
        <label>
          Код валюты
          <v-autocomplete
            ref="currency_letter"
            v-model="about_guarantee.currency_letter"
            auto-select-first
            :items="currencies"
            item-text="letterCode"
            item-value="letterCode"
            outlined
            background-color="white"
            dense
            hide-details="auto"
            :filter="filterBySearchField"
          />
        </label>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import {mapGetters} from 'vuex'
import { filterBySearchFieldMixin } from "@/mixins/catalogs"
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { showCatalog, catalogValueSelected } from "@/events/statistics";
import {about_guarantee as onAboutGuarantee} from "@/events/statistics/control";
import {highlightField} from "@/helpers/control";

export default {
  mixins:[blockAutoUpdate, filterBySearchFieldMixin],
  data:() => ({
    block_id: 'statistics-about-guarantee',
    guarantee:[],
    about_guarantee:{
      declaration_id: null,
      payment_type: null,
      guarantee_type: null,
      guarantee_number: null,
      sum: null,
      currency_letter: null
    }
  }),
  computed:{
    ...mapGetters({
      user:"auth/getUser",
      selected: 'ensuring/getSelected',
      currencies: "catalogs/getNsiCurrencies",
      reasons:"catalogs/getNsiGuaranteeReasons",
      types:"catalogs/gethNsiGuaranteeTypesSo"
    }),
    isRegistered(){
      return this.selected?.registration_details?.reg_num_a
    }
  },
  watch: {
    selected: {
      handler(){
        this.setFields();
      },
      deep:true
    }
  },
  created(){
    onAboutGuarantee.subscribe(this.onControl)
    catalogValueSelected.subscribe(this.updatesResolver, [
      'about_guarantee.guarantee_number',
      'about_guarantee.guarantee_type',
      'about_guarantee.payment_type'
    ])
    this.fetchCatalog()
  },
  beforeDestroy(){
    onAboutGuarantee.unsubscribe()
    catalogValueSelected.unsubscribe()
  },
  methods:{
    highlightField,
    onControl({path}){
      highlightField.call(this, {path})
    },
    readyToUpdate(){
      this.fields_been_visited = true
      this.hasChanges = true
    },
    fetchCatalog(){
      const divisionId = this.user?.user?.activeDivisionId
      this.$store.dispatch("catalogs/fetchGuaranteeRoot", {divisionId}).then(res => {
        this.guarantee = res.data ?? []
      }).catch(() => {
        this.$snackbar({text:'Не удалось загрузить справочник', color:'red', top:false})
      });
    },
    updateCurrency(code){
      const {currency_letter} = this.guarantee.find(i => i.number === code) ?? {}
      this.about_guarantee.currency_letter = currency_letter
    },
    updatesResolver({field, code}){
      if(code && field){
        const [parent, child] = field.split('.')
        this[parent][child] = code
        if(field === 'about_guarantee.guarantee_number'){
          this.updateCurrency(code)
        }
        this.readyToUpdate()
      }
    },
    openGuaranteeTypesSo(){
      showCatalog.trigger({
        type: "guarantee_types_so",
        search: this.about_guarantee.payment_type,
        field:"about_guarantee.payment_type"
      });
    },
    openGuaranteeReasons(){
      showCatalog.trigger({
        type: "guarantee_reasons",
        search: this.about_guarantee.guarantee_type,
        field:"about_guarantee.guarantee_type"
      });
    },
    openGuarantee(){
      showCatalog.trigger({
        type: "guarantee",
        search: this.about_guarantee.guarantee_number,
        field:"about_guarantee.guarantee_number" });
    },
    uploadData(){
      const about_guarantee = this.convertEmptyStringsToNull(this.about_guarantee);
      return this.$store.dispatch("ensuring/uploadBlockData", {
        name: "about_guarantee",
        value: about_guarantee,
      });

    },
    setFields() {
      const { about_guarantee } = this.selected || {};
      Object.keys(about_guarantee).forEach(key => {
        if (key in this.about_guarantee) {
          this.about_guarantee[key] = about_guarantee[key];
        }
      })
    },
  }
}
</script>
