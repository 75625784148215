<template>
  <v-card
    :id="block_id"
    v-click-outside="triggerOnFocus"
    color="grey"
    class="lighten-3 elevation-0 rounded-lg pa-3"
    :class="{'blocked-box' : isRegistered}"
  >
    <h4>Сведения о лице, сформировавшим свидетельство</h4>
    <v-row>
      <v-col
        cols="2"
        class="pr-0"
      >
        <label>
          Фамилия
          <v-text-field
            v-model="filler_json.last_name"
            outlined
            background-color="white"
            dense
            hide-details="auto"
          />
        </label>
      </v-col>
      <v-col
        cols="2"
        class="pr-0"
      >
        <label>
          Имя
          <v-text-field
            v-model="filler_json.first_name"
            outlined
            background-color="white"
            dense
            hide-details="auto"
          />
        </label>
      </v-col>
      <v-col
        cols="2"
        class="pr-0"
      >
        <label>
          Отчество
          <v-text-field
            v-model="filler_json.middle_name"
            outlined
            background-color="white"
            dense
            hide-details="auto"
          />
        </label>
      </v-col>
      <v-col
        cols="4"
        class="pr-0"
      >
        <label>
          Должность
          <v-text-field
            v-model="filler_json.position"
            outlined
            background-color="white"
            dense
            hide-details="auto"
          />
        </label>
      </v-col>
      <v-col
        cols="2"
      >
        <label>
          Телефон
          <v-text-field
            v-model="filler_json.phone"
            outlined
            background-color="white"
            dense
            hide-details="auto"
          />
        </label>
      </v-col>
    </v-row>
    <h5 class="py-2">
      Документ, удостоверяющий полномочия
    </h5>
    <v-row>
      <v-col
        cols="4"
        class="pr-0"
      >
        <label>
          Наименование документа
          <v-text-field
            v-model="filler_json.auth_doc_name"
            outlined
            background-color="white"
            dense
            hide-details="auto"
          />
        </label>
      </v-col>
      <v-col
        cols="2"
        class="pr-0"
      >
        <label>
          Номер документа
          <v-text-field
            v-model="filler_json.auth_doc_number"
            outlined
            background-color="white"
            dense
            hide-details="auto"
          />
        </label>
      </v-col>
      <v-col
        cols="2"
        class="pr-0"
      >
        <label>
          Дата действия с</label>
        <date-picker
          v-model="filler_json.auth_doc_d_on"
          :visited.sync="fields_been_visited"
          :has-changes.sync="hasChanges"
        />
      </v-col>
      <v-col
        cols="2"
      >
        <label>
          Дата действия по</label>
        <date-picker
          v-model="filler_json.auth_doc_d_off"
          :visited.sync="fields_been_visited"
          :has-changes.sync="hasChanges"
        />
      </v-col>
    </v-row>
  </v-card>
</template>


<script>
import {mapGetters} from 'vuex'
import { filterBySearchFieldMixin } from "@/mixins/catalogs"
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { getCountryNameByCode } from "@/helpers/catalogs"
import DatePicker from '@/components/shared/custom-date-picker.vue'
export default {
  components:{
    DatePicker
  },
  mixins:[blockAutoUpdate, filterBySearchFieldMixin],
  data:() => ({
    block_id: 'statistics-filler',
    filler:{
      declaration_id: null,
    },
    filler_json: {
      last_name:null,
      first_name: null,
      middle_name:null,
      position: null,
      phone: null,
      auth_doc_name: null,
      auth_doc_number:null,
      auth_doc_d_on: null,
      auth_doc_d_off: null,
    }
  }),
  computed:{
    ...mapGetters({
      selected: 'ensuring/getSelected',
    }),
    isRegistered(){
      return this.selected?.registration_details?.reg_num_a
    },
  },
  watch: {
    selected: {
      handler(){
        this.setFields();
      },
      deep:true
    }
  },
  methods:{
    uploadData(){
      const payload = {
        ...this.filler,
        filler_json:{
          ...this.filler_json,
          country_name: getCountryNameByCode(this.countries, this.filler_json.country_letter),
        }
      }
      const filler = this.convertEmptyStringsToNull(payload);
      return this.$store.dispatch("ensuring/uploadBlockData", {
        name: "filler",
        value: filler,
      });

    },
    setFields() {
      const filler = this.selected.filler || {}
      const filler_json = filler.filler_json

      Object.keys(filler).forEach(key => {
        if (key in this.filler) {
          this.filler[key] = filler[key];
        }
      })

      Object.keys(filler_json).forEach(key => {
        if (key in this.filler_json) {
          this.filler_json[key] = filler_json[key];
        }
      })
    },
  }
}
</script>
